import React, { useEffect, useRef, useCallback } from 'react'
import { Link } from 'gatsby'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import classnames from 'classnames'
import { BsBoxArrowUpRight } from 'react-icons/bs'
import {
  Collapse,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Navbar,
  Nav,
  UncontrolledDropdown,
} from 'reactstrap'
import {
  HOME_PAGE,
  GODMOTHER_PAGE,
  EVENTS_PAGE,
  MEMBERS_PAGE,
  BLOG_LIST,
  TOUR_PAGE,
  PARTENARIAT_PAGE,
  DEVENIR_PARTENAIRE,
} from 'shared/routes'
import { Location } from 'shared/types'
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl'
import HeaderLogo from './HeaderLogo'
import HeaderItem from './HeaderItem'
import Language from '../Language'

interface Header {
  location: Location
  contributionUrl: string
  merchandiseUrl: string
}

const Header = ({ location, contributionUrl, merchandiseUrl }: Header): JSX.Element => {
  const [navbarColor, setNavbarColor] = React.useState('navbar-transparent')
  const [navbarCollapse, setNavbarCollapse] = React.useState(false)
  const menuRef = useRef<HTMLDivElement | null>(null)
  const language = useIntl().locale || 'fr'
  const godmotherUrlWithLocale = language === 'en' ? `/en${GODMOTHER_PAGE}` : GODMOTHER_PAGE
  const devenirPartenaireUrlWithLocale = language === 'en' ? `/en${PARTENARIAT_PAGE}` : PARTENARIAT_PAGE

  const toggleNavbarCollapse = useCallback(() => {
    setNavbarCollapse(!navbarCollapse)
    document.documentElement.classList.toggle('nav-open')
  }, [navbarCollapse, setNavbarCollapse])

  const navbarScroll = () => {
    if (typeof window === 'undefined') {
      return
    }

    if (window.innerWidth < 992) {
      toggleNavbarCollapse()
    }

    window.scroll({
      top: 0,
      behavior: 'smooth',
    })
  }

  const setColor = (pathname: string) => {
    const width = window.innerWidth / 5.5
    if (
      pathname === HOME_PAGE ||
      pathname === '/fr/' ||
      pathname === '/en/' ||
      pathname === '/fr' ||
      pathname === '/en'
    ) {
      if (document.documentElement.scrollTop > width - 1 || document.body.scrollTop > width - 1) {
        setNavbarColor('')
      } else if (document.documentElement.scrollTop < width || document.body.scrollTop < width) {
        setNavbarColor('navbar-transparent')
      }
    } else {
      setNavbarColor('')
    }
  }

  useEffect(() => {
    const updateNavbarColor = () => {
      setColor(location.pathname)
    }
    setColor(location.pathname)
    window.addEventListener('scroll', updateNavbarColor)
    return function cleanup() {
      window.removeEventListener('scroll', updateNavbarColor)
    }
  }, [setNavbarColor, location])

  useEffect(() => {
    function handleClickOutside(e: globalThis.MouseEvent) {
      if (
        !menuRef.current?.className.includes('collapsing') &&
        navbarCollapse &&
        !menuRef.current?.contains(e.target as Node)
      ) {
        toggleNavbarCollapse()
      }
    }

    document.addEventListener('click', handleClickOutside)
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [menuRef, navbarCollapse, toggleNavbarCollapse])

  return (
    <Navbar className={classnames('fixed-top', navbarColor)} color-on-scroll="300" expand="lg">
      <Container>
        <HeaderLogo navbarCollapse={navbarCollapse} toggleNavbarCollapse={toggleNavbarCollapse} />
        <Collapse className="justify-content-end" navbar isOpen={navbarCollapse} innerRef={menuRef}>
          <Nav navbar>
            <HeaderItem titleLabel="navbar_member" toggleNavbar={toggleNavbarCollapse} url={MEMBERS_PAGE} />
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                <FormattedMessage id="navbar_partenariat" />
              </DropdownToggle>
              <DropdownMenu right className="dropdown-marrainage">
                <DropdownItem to={devenirPartenaireUrlWithLocale} tag={Link} onClick={navbarScroll}>
                  <FormattedMessage id="navbar_ourPartners" />
                </DropdownItem>
                <DropdownItem
                  onAnchorLinkClick={navbarScroll}
                  stripHash
                  tag={AnchorLink}
                  to={`${devenirPartenaireUrlWithLocale}#${DEVENIR_PARTENAIRE}`}
                >
                  <FormattedMessage id="navbar_devenirPartenaire" />
                </DropdownItem>
                <DropdownItem href={contributionUrl} target="_blank" className="dropdown-item">
                  <FormattedMessage id="navbar_contribute" />
                  <BsBoxArrowUpRight size={20} />
                </DropdownItem>
                <DropdownItem href={merchandiseUrl} target="_blank" className="dropdown-item">
                  <FormattedMessage id="navbar_shop" />
                  <BsBoxArrowUpRight size={20} />
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
            <HeaderItem titleLabel="navbar_event" toggleNavbar={toggleNavbarCollapse} url={EVENTS_PAGE} />
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                <FormattedMessage id="navbar_mentorship" />
              </DropdownToggle>
              <DropdownMenu right className="dropdown-marrainage">
                <DropdownItem to={godmotherUrlWithLocale} tag={Link} onClick={navbarScroll}>
                  <FormattedMessage id="navbar_mentorship" />
                </DropdownItem>
                <DropdownItem to={TOUR_PAGE} tag={Link} onClick={navbarScroll}>
                  <FormattedMessage id="navbar_tour" />
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
            <HeaderItem titleLabel="navbar_blog" toggleNavbar={toggleNavbarCollapse} url={BLOG_LIST} />
            <Language color={navbarColor} />
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  )
}

export default Header
