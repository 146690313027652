import React, { useRef } from 'react'
import { Mentor, MentorsPage } from 'shared/types'
import DescriptionSection from './DescriptionSection'
import GodmotherSection from './GodmotherSection'
import GodmotherFormSection from './GodmotherFormSection'

interface GodmotherPage {
  mentors: Mentor[]
  mentorsContent: MentorsPage
}
const GodmotherPage = ({ mentors, mentorsContent }: GodmotherPage): JSX.Element => {
  const formRef = useRef<HTMLDivElement | null>(null)
  const scrollToForm = () => {
    formRef.current?.scrollIntoView({
      behavior: 'smooth',
    })
  }

  return (
    <div className="main mentorPage">
      <DescriptionSection
        scrollToForm={scrollToForm}
        title={mentorsContent.title}
        mentorDescription={mentorsContent.mentorDescription}
      />
      <GodmotherSection mentors={mentors} />
      <GodmotherFormSection formRef={formRef} mentors={mentors} />
    </div>
  )
}

export default GodmotherPage
