import React from 'react'
import SponsorsSection from './SponsorsSection'
import PartnerImageSection from './PartnerImageSection'
import PartenariatFormSection from './PartenariatFormSection'

interface Sponsor {
  id: string
  name: string
  url: string
  image: string
}

interface PartenariatPageProps {
  sponsors: Sponsor[]
  contributionUrl: string
  merchandiseUrl: string
}

const PartenariatPage = ({ sponsors, contributionUrl, merchandiseUrl }: PartenariatPageProps): JSX.Element => {
  return (
    <>
      <PartnerImageSection contributionUrl={contributionUrl} merchandiseUrl={merchandiseUrl} />
      <div className="main">
        <div className="section text-center ">
          <SponsorsSection sponsors={sponsors} />
          <PartenariatFormSection formRef={null} />
        </div>
      </div>
    </>
  )
}

export default PartenariatPage
