import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import {
  ContentfulTheme,
  ContentfulBlogListEntry,
  ContentfulBlogPost,
  ContentfulMembersListData,
  ContentfulMentor,
  MembersList,
  Mentor,
  Theme,
  BlogPostSummary,
  BlogEntry,
  ContentfulHomePageEntry,
  HomePageContent,
  ContentfulProgramStatsData,
  ProgramStatsEntry,
  ContentfulSponsorsData,
  ContentfulSponsorEntry,
  SponsorTier,
  ContentfulTourneePage,
  TourneePage,
  ContentfulMentorsPage,
  MentorsPage,
  ContentfulEvent,
  ContentfulEventAlbum,
  EventPageContent,
  ContentfulGeneralContent,
  GeneralContent,
} from 'shared/types'

export function mapToBlogListFields(data: ContentfulBlogListEntry): BlogPostSummary {
  return {
    id: data.contentful_id,
    title: data.blogTitle,
    author: data.blogAuthor,
    summary: documentToReactComponents(JSON.parse(data.blogSummary.raw)),
    date: data.blogPublishDate,
    imageUrls: data.blogImages.map((img) => img.fixed.src),
  }
}

export function mapToBlogEntry(data: ContentfulBlogPost): BlogEntry {
  return {
    id: data.contentfulBlog.contentful_id,
    title: data.contentfulBlog.blogTitle,
    text: documentToReactComponents(JSON.parse(data.contentfulBlog.blogText.raw)),
    author: data.contentfulBlog.blogAuthor,
    date: data.contentfulBlog.blogPublishDate,
    imageUrls: data.contentfulBlog.blogImages.map((img) => img.fixed.src),
    references: data.contentfulBlog.blogReferences?.raw
      ? documentToReactComponents(JSON.parse(data.contentfulBlog.blogReferences?.raw))
      : undefined,
  }
}

export function mapToMentors(data: ContentfulMentor): Mentor {
  return {
    id: data.contentful_id,
    name: data.mentorName,
    description: data.mentorDescription.mentorDescription,
    programs: data.mentorPrograms,
    image: data.mentorImage.fixed.src,
  }
}

export function mapToMentorsContentFields(data: ContentfulMentorsPage): MentorsPage {
  return {
    id: data.contentful_id,
    mentorDescription: documentToReactComponents(JSON.parse(data.mentorDescription.raw)),
    title: data.title,
  }
}

export function mapToThemes(data: ContentfulTheme): Theme {
  return {
    id: data.contentful_id,
    title: data.title,
    description: documentToReactComponents(JSON.parse(data.description.raw)),
    image: data.image.fixed.src,
  }
}

export function mapToTourContentFields(data: ContentfulTourneePage): TourneePage {
  return {
    id: data.contentful_id,
    title: data.title,
    description: documentToReactComponents(JSON.parse(data.description.raw)),
    googleForm: data.googleForm,
  }
}

export function mapToMembersList(data: ContentfulMembersListData): MembersList[] {
  return data.allContentfulMembersList.nodes.map((node) => ({
    category: node.category,
    members: node.members.map((member) => ({
      id: member.contentful_id,
      name: member.name,
      title: member.title || undefined,
      genie: member.genie || undefined,
      urlLinkedIn: member.urlLinkedIn || undefined,
      image: member.image?.fixed.src || undefined,
    })),
  }))
}

export function mapToHomePageFields(data: ContentfulHomePageEntry): HomePageContent {
  return {
    id: data.contentful_id,
    title: data.title,
    subtitle: data.subtitle,
    contributionUrl: data.contributionUrl,
    missionTitle: data.missionTitle,
    missionDescription: documentToReactComponents(JSON.parse(data.missionDescription.raw)),
    firstObjectiveTitle: data.firstObjectiveTitle,
    firstObjectiveDescription: documentToReactComponents(JSON.parse(data.firstObjectiveDescription.raw)),
    secondObjectiveTitle: data.secondObjectiveTitle,
    secondObjectiveDescription: documentToReactComponents(JSON.parse(data.secondObjectiveDescription.raw)),
    faqTitle: data.faqTitle,
    faqSection: data.faqSection.map((faq: { reponse: { raw: string }; question: string }) => ({
      reponse: documentToReactComponents(JSON.parse(faq.reponse.raw)),
      question: faq.question,
    })),
  }
}

export function mapToGeneralPageFields(data: ContentfulGeneralContent): GeneralContent {
  return {
    id: data.contentful_id,
    contributionUrl: data.contributionUrl,
    merchandiseUrl: data.merchandiseUrl,
  }
}

export function mapToStats(data: ContentfulProgramStatsData): ProgramStatsEntry[] {
  return data.nodes.map((node) => ({
    ctn: node.ctn,
    doc: node.doc,
    ele: node.ele,
    gol: node.gol,
    gpa: node.gpa,
    log: node.log,
    master: node.master,
    mec: node.mec,
    ti: node.ti,
    year: node.year,
  }))
}

export function mapToEvents(data: ContentfulEvent): EventPageContent {
  return {
    id: data.contentful_id,
    eventTitle: data.eventTitle,
    eventAlbum: data.eventAlbum
      ? data.eventAlbum.map((album: ContentfulEventAlbum) => ({
          id: album.contentful_id,
          albumTitle: album.albumTitle,
          albumSubtitle: album.albumSubtitle,
          albumPictures: album.albumPictures?.map((image) => image.fixed?.src),
        }))
      : null,
    eventIcon: data.eventIcon?.fixed.tracedSVG,
  }
}

export function mapToSponsorTiers(data: ContentfulSponsorsData): SponsorTier[] {
  return data.nodes.map((node) => ({
    id: node.contentful_id,
    tier_id: node.tier_id,
    name: node.name,
    icon: node.icon,
    sponsors: node.sponsor
      ? node.sponsor.map((s: ContentfulSponsorEntry) => ({
          id: s.contentful_id,
          name: s.name,
          url: s.url,
          image: s.image?.fixed.src || undefined,
        }))
      : null,
  }))
}
