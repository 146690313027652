import React, { useState, RefObject } from 'react'
import Select, { ValueType } from 'react-select'

import {
  Button,
  Col,
  Container,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Row,
} from 'reactstrap'
import { toast } from 'react-toastify'
import emailjs from 'emailjs-com'
import { ToastConfigs, FORM_TYPES } from 'shared/constants'
import { DEVENIR_PARTENAIRE } from 'shared/routes'
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl'
import fr from '../../intl/fr.json'
import en from '../../intl/en.json'

interface Option {
  value: string
  label: string
}

interface PartenariatFormSectionProps {
  formRef: RefObject<HTMLDivElement> | null
}

const PartenariatFormSection = ({ formRef }: PartenariatFormSectionProps): JSX.Element => {
  // Static list of partnership levels
  const tierOptions: Option[] = [
    { label: 'Gold', value: 'gold' },
    { label: 'Silver', value: 'silver' },
    { label: 'Bronze', value: 'bronze' },
  ]

  const [organisationName, setOrganisationName] = useState('')
  const [email, setEmail] = useState('')
  const [contactFirstName, setContactFirstName] = useState('')
  const [contactLastName, setContactLastName] = useState('')
  const [objective, setObjective] = useState('')
  const [targetTier, setTargetTier] = useState<Option | null>(tierOptions[0])
  const [message, setMessage] = useState('')

  const intl = useIntl()

  const resetInputs = () => {
    setOrganisationName('')
    setEmail('')
    setContactFirstName('')
    setContactLastName('')
    setObjective('')
    setTargetTier(tierOptions[0])
    setMessage('')
  }

  const sendEmail = () => {
    if (organisationName && email && contactFirstName && contactLastName && objective && targetTier && message) {
      let emailBody = `${fr.partner_form_objective}: ${objective}\n\n`
      emailBody += `${fr.partner_form_target_tier}: ${targetTier.label}\n\n`
      emailBody += `${message}`
      emailjs
        .send(
          process.env.GATSBY_EMAIL_SERVICE_ID || '',
          process.env.GATSBY_CONTACT_FORM_TEMPLATE_ID || '',
          {
            nom: `${contactFirstName} ${contactLastName}`,
            email,
            subject: `${organisationName} - ${FORM_TYPES.sponsorForm}`,
            message: emailBody,
          },
          process.env.GATSBY_EMAIL_USER_ID || '',
        )
        .then(() => {
          toast.success(
            intl.locale === 'en' ? en.homepage_contact_form_sent : fr.homepage_contact_form_sent,
            ToastConfigs,
          )
          resetInputs()
        })
        .catch(() => {
          toast.error(
            intl.locale === 'en' ? en.homepage_contact_form_error : fr.homepage_contact_form_error,
            ToastConfigs,
          )
        })
    } else {
      toast.error(intl.locale === 'en' ? en.homepage_contact_form_fill : fr.homepage_contact_form_fill, ToastConfigs)
    }
  }

  return (
    <div className="text-center" ref={formRef} id={DEVENIR_PARTENAIRE}>
      <Container>
        <Row>
          <Col className="ml-auto mr-auto" md="8">
            <h2 className="title text-center">
              <FormattedMessage id="partnerpage_become_partner" />
            </h2>
            <div className="contact-form">
              <Row>
                <Col md="6">
                  <Label>
                    <FormattedMessage id="partner_form_organisation_name" />
                  </Label>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="nc-icon nc-bag-16" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder={
                        intl.locale === 'en' ? en.partner_form_organisation_name : fr.partner_form_organisation_name
                      }
                      type="text"
                      value={organisationName}
                      onChange={(e) => setOrganisationName(e.target.value)}
                    />
                  </InputGroup>
                </Col>
                <Col md="6">
                  <Label>
                    <FormattedMessage id="partner_form_email" />
                  </Label>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="nc-icon nc-email-85" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder={intl.locale === 'en' ? en.partner_form_email : fr.partner_form_email}
                      type="text"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </InputGroup>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <Label>
                    <FormattedMessage id="partner_form_first_name" />
                  </Label>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="nc-icon nc-single-02" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder={intl.locale === 'en' ? en.partner_form_first_name : fr.partner_form_first_name}
                      type="text"
                      value={contactFirstName}
                      onChange={(e) => setContactFirstName(e.target.value)}
                    />
                  </InputGroup>
                </Col>
                <Col md="6">
                  <Label>
                    <FormattedMessage id="partner_form_last_name" />
                  </Label>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="nc-icon nc-single-02" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder={intl.locale === 'en' ? en.partner_form_last_name : fr.partner_form_last_name}
                      type="text"
                      value={contactLastName}
                      onChange={(e) => setContactLastName(e.target.value)}
                    />
                  </InputGroup>
                </Col>
              </Row>
              <FormGroup className="col-md-16">
                <Label for="inputState">
                  <FormattedMessage id="partner_form_objective" />
                </Label>
                <Input
                  type="text"
                  placeholder={intl.locale === 'en' ? en.partner_form_objective : fr.partner_form_objective}
                  value={objective}
                  onChange={(e) => setObjective(e.target.value)}
                />
              </FormGroup>
              <FormGroup className="col-md-16">
                <Label for="inputState">
                  <FormattedMessage id="partner_form_target_tier" />
                </Label>
                <Select
                  classNamePrefix="contact"
                  name="sujet"
                  value={targetTier}
                  options={tierOptions}
                  placeholder={intl.locale === 'en' ? en.partner_form_target_tier : fr.partner_form_target_tier}
                  onChange={(value: ValueType<Option, false>) => setTargetTier(value as Option)}
                />
              </FormGroup>
              <Label>
                <FormattedMessage id="homepage_contact_message" />
              </Label>
              <Input
                placeholder={intl.locale === 'en' ? en.homepage_contact_message : fr.homepage_contact_message}
                type="textarea"
                rows="6"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
              <Row>
                <Col className="ml-auto mr-auto center" md="4">
                  <Button className="btn-fill" color="primary" size="lg" onClick={sendEmail}>
                    <FormattedMessage id="homepage_contact_send" />
                  </Button>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default PartenariatFormSection
