import React from 'react'
import './SponsorsSection.css'

interface Sponsor {
  id: string
  name: string
  url: string
  image: string
}

interface SponsorsSectionProps {
  sponsors?: Sponsor[]
}
const SponsorsSection: React.FC<SponsorsSectionProps> = ({ sponsors = [] }) => {
  return (
    <div className="sponsors-section">
      <h2 className="section-title">Nos Partenaires</h2>
      <div className="sponsors-grid">
        {sponsors.map((sponsor) => (
          <a key={sponsor.id} href={sponsor.url} target="_blank" rel="noopener noreferrer" className="sponsor-card">
            <img src={sponsor.image} alt={sponsor.name} className="sponsor-logo" />
          </a>
        ))}
      </div>
    </div>
  )
}

export default SponsorsSection
