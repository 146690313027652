import Footer from 'components/Footer'
import Header from 'components/Header'
import PageHelmet from 'components/PageHelmet'
import ScrollToTop from 'components/ScrollToTop'
import PartenariatPage from 'components/PartenariatPage'
import React from 'react'
import { ToastContainer } from 'react-toastify'
import { graphql } from 'gatsby'
import { baseUrl, genericdescription, genericTitle, homeImage } from 'shared/constants'
import { PARTENARIAT_PAGE } from 'shared/routes'
import { ContentfulPartenariatPageData, Location } from 'shared/types'
import { mapToGeneralPageFields } from 'services'

interface Sponsor {
  id: string
  name: string
  url: string
  image: string
}

interface PartenariatProps {
  location: Location
  data: ContentfulPartenariatPageData
}

const Partenariat = ({ location, data }: PartenariatProps): JSX.Element => {
  const content = mapToGeneralPageFields(data.contentfulGeneralPageData)

  // Deduplicate sponsors based on contentful_id with explicit typing
  const sponsors: Sponsor[] = Array.from(
    new Map<string, Sponsor>(
      data.allContentfulSponsor.nodes.map((node: any) => [
        node.contentful_id, // Use contentful_id as the unique key
        {
          id: node.contentful_id,
          name: node.name,
          url: node.url,
          image: node.image?.fixed?.src,
        },
      ]),
    ).values(),
  )

  return (
    <>
      <PageHelmet
        title={genericTitle}
        description={genericdescription}
        url={`${baseUrl}${PARTENARIAT_PAGE}`}
        image={homeImage}
      />
      <ScrollToTop location={location} />
      <ToastContainer />
      <Header location={location} contributionUrl={content.contributionUrl} merchandiseUrl={content.merchandiseUrl} />
      <PartenariatPage
        sponsors={sponsors} // Pass flat list of sponsors
        contributionUrl={content.contributionUrl}
        merchandiseUrl={content.merchandiseUrl}
      />
      <Footer />
    </>
  )
}

export const query = graphql`
  query PartenariatPage {
    allContentfulSponsor {
      nodes {
        name
        url
        image {
          fixed(height: 800, width: 800) {
            src
          }
        }
        contentful_id
      }
    }
    contentfulGeneralPageData {
      contentful_id
      contributionUrl
      merchandiseUrl
    }
  }
`

export default Partenariat
